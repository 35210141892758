let api_key_: string | null = null;
import { Me, LoginState } from "./openapi_types.ts";
import { client } from "@hey-api/client-fetch";
import { skipApiKey } from "./client/skip_api_key.gen.ts";

const url = new URL(window.location.href);
const params = new URLSearchParams(url.search);
const polyjuice = params.get("polyjuice");

export enum Verb {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
}
function guarantee_api_key() {
  return new Promise<string>((resolve, reject) => {
    if (api_key_ == null) {
      fetch("/api/key/?path=" + encodeURIComponent(window.location.pathname))
        .then((response) => {
          if (!response.ok) {
            window.location.href = "/";
            reject("Not logged in");
          }
          return response.json();
        })
        .then((data) => {
          api_key_ = data.key;
          resolve(api_key_!);
        })
        .catch((error) => reject(error));
    } else {
      resolve(api_key_);
    }
  });
}
export function api_call_no_key(
  path: string,
  verb: Verb | null,
  body: object | null,
) {
  if (verb == null) {
    verb = Verb.GET;
  }
  if (body == null) {
    body = {};
  }

  const headers: Record<string, string> = {};
  const options: RequestInit = { method: verb, headers: headers };
  if (verb == Verb.POST || verb == Verb.PUT) {
    options["body"] = JSON.stringify(body);
    headers["Content-Type"] = "application/json";
  }

  return fetch(path, options).then((response) => response.json());
}

export function api_call(path: string, verb: Verb | null, body: object | null) {
  if (polyjuice) {
    path = path + "?polyjuice=" + polyjuice;
  }
  return guarantee_api_key().then((api_key_value) => {
    const api_key = api_key_value as string;

    if (verb == null) {
      verb = Verb.GET;
    }
    if (body == null) {
      body = {};
    }

    const headers: Record<string, string> = { Authorization: api_key };
    const options: RequestInit = { method: verb, headers: headers };
    if (verb == Verb.POST || verb == Verb.PUT) {
      options["body"] = JSON.stringify(body);
      headers["Content-Type"] = "application/json";
    }

    return fetch("/api/" + path, options).then(async (response) => {
      if (response.status != 200) {
        console.log(
          "api_call error",
          path,
          response.status,
          response.statusText,
          await response.text(),
        );
        return;
      }
      return response.json();
    });
  });
}

class Deferred<T> {
  public resolve: (value: T) => void = () => {};
  public reject: (reason?: any) => void = () => {};
  public promise: Promise<T>;

  constructor() {
    const self = this;

    this.promise = new Promise<T>(function (resolve, reject) {
      self.reject = reject;
      self.resolve = resolve;
    });
  }
}

const get_me = (function () {
  var me: Me | null = null;
  var pending_promises: Deferred<Me>[] | null = null;

  return async function (): Promise<Me> {
    if (me != null) {
      console.log("me not null", me);
      return Promise.resolve(me);
    }
    if (pending_promises != null) {
      const p = new Deferred<Me>();
      pending_promises.push(p);
      console.log("me deferred", p);
      return p.promise;
    }
    pending_promises = [];
    return fetch("/api/users/me")
      .then((res) => res.json())
      .then((res) => {
        me = res;
        console.log("Got result", me);
        if (me == null) {
          throw new Error("me is null");
        }
        if (pending_promises != null) {
          if (me != null) {
            console.log("resolving promises", pending_promises);
            const resolved_me: Me = me;
            pending_promises.forEach((p) => p.resolve(resolved_me));
          }
          pending_promises = null;
        }
        return me;
      });
  };
})();

export { get_me };

export async function get_login_state() {
  return api_call_no_key("/api/users/login_state", null, null).then((data) => {
    return data as LoginState;
  });
}

function initialize_interceptors() {
  client.interceptors.request.use(async (request, options) => {
    if (
      skipApiKey.find((e) => {
        return e[0] == options.url && e[1] == options.method;
      }) != undefined
    ) {
      return request;
    }
    request.headers.set("Authorization", await guarantee_api_key());
    return request;
  });
}
initialize_interceptors();
