export const skipApiKey: [string, string][] = [
  ["/api/embed/", "POST"],
  ["/api/key/", "GET"],
  ["/api/users/check_invitation", "GET"],
  ["/api/users/naics", "GET"],
  ["/api/users/create_user", "POST"],
  ["/api/users/login", "POST"],
  ["/api/users/request_pin", "POST"],
  ["/api/users/pin", "POST"],
  ["/api/users/me", "GET"],
  ["/api/users/login_state", "GET"],
  ["/api/webhooks/csp_report", "POST"],
  ["/welcome/google/login", "GET"],
  ["/welcome/google/auth", "GET"],
  ["/welcome/intuit/login", "GET"],
  ["/welcome/intuit/auth", "GET"],
  ["/welcome/intuit/disconnect", "GET"],
  ["/welcome/change_password/", "POST"],
  ["/welcome/request_password_reset/", "POST"],
  ["/welcome/logout/", "GET"],
  ["/welcome/forget_computer/", "GET"],
]
