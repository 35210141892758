<script lang="ts" setup>
import { ref, computed } from "vue";
import { get_me } from "../api.ts";

const me = ref(await get_me());
const initials = computed(() => {
  const name = me.value.user?.full_name;
  if (name && name.length > 0) {
    return name
      .split(" ")
      .map((chunk: string) => chunk[0])
      .join("");
  }
  return "";
});
</script>
<template>
  <Suspense>
    <div v-if="me.user != null" id="header-spacer" class="dropdown">
      <button class="large">
        <span>{{ initials }}</span> {{ me.user.full_name }}
      </button>
      <ul class="dropdown-content">
        <li><a href="/quick/manage_account">Manage Account</a></li>
        <li><a href="/admin/" v-if="me.admin">Admin</a></li>
        <li><a href="/welcome/logout/">Log Out</a></li>
      </ul>
    </div>
  </Suspense>
</template>

<style scoped>
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
}

.dropdown:hover .dropdown-content {
  display: block;
}
</style>
